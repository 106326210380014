export const buildHtmlList = ({ data }) => {
  return (
    "<ul>\n" +
    data
      .map((i) => "  <li><b>" + i.label + " :</b> " + i.value + " </li>\n")
      .toString()
      .replace(/\,/g, "") +
    "</ul>\n"
  )
}
