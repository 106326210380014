import React, { useState, useEffect } from "react"
import { Box } from "@chakra-ui/react"
import QuoteHeader from "./QuoteHeader"
import QuoteSubHeader from "./QuoteSubHeader"
import QuoteBody from "./QuoteBody"
import "../../../styles/contact-quote.css"
import { useEmailQuoteRequestStatusContext } from "../../context/EmailQuoteRequestStatusProvider"

const QuoteInfos = (props) => {
  const [offsetY, setOffsetY] = useState(0)
  const handleScroll = () => setOffsetY(window.pageYOffset)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const { emailQuoteRequestStatus } = useEmailQuoteRequestStatusContext()
  return (
    <Box p={{ base: 3, md: 6 }}>
      {emailQuoteRequestStatus ? <QuoteHeader code_setem = {props.code_setem ? props.code_setem : ""}
                                              des_usine = {props.des_usine ? props.des_usine : ""}
                                              code_usine = {props.code_usine ? props.code_usine : ""} /> : null}
      <QuoteSubHeader />
      <QuoteBody />
      <div className="contact-parallax-a">
        <img
          className="parallax-a"
          src={`https://${process.env.GATSBY_CURRENT_URL}/wp-content/uploads/2021/12/parralax_a.png`}
          style={{ transform: `translateY(${offsetY * 0.2}px)` }}
        />
      </div>
      <div className="contact-parallax-b">
        <img
          className="parallax-b"
          src={`https://${process.env.GATSBY_CURRENT_URL}/wp-content/uploads/2021/12/parralax_b.png`}
          style={{ transform: `translateY(${offsetY * 0.2}px)` }}
        />
      </div>
    </Box>
  )
}

export default QuoteInfos
