import {Box, Spinner, useToast} from "@chakra-ui/react"
import React, {useEffect, useRef, useState} from "react"
import "../../../styles/contact-form.css"
import {useEmailQuoteRequestStatusContext} from "../../context/EmailQuoteRequestStatusProvider"
import QuoteHeader from "./QuoteHeader"
import {buildHtmlList} from "./utils"
import ReCAPTCHA from "react-google-recaptcha";

const profiles = {
    PROFESSIONNEL: "professionnel",
    PARTICULIER: "particulier",
    PRO_ETRANGER: "pro-etranger",
}

const ContactForm = (props) => {

    const [codeSetem, setCodeSetem] = useState((props.code_setem ? props.code_setem : ""));
    const [desUsine, setDesUsine] = useState((props.des_usine ? props.des_usine : ""));
    const [codeUsine, setCodeUsine] = useState((props.code_usine ? props.code_usine : ""));

    //https://www.youtube.com/watch?v=-_hRWBpaJZk
    const endPoint = `${process.env.GATSBY_AWS_MAIL_ATTACHMENT}`
    const email_from = `${process.env.GATSBY_AWS_MAIL_FROM}`
    const email_to = `${process.env.GATSBY_AWS_MAIL_TO}`
    const site_key = `${process.env.GATSBY_RECAPTCHA_SITE_KEY}`
    const labelClass = "setem-form-label"
    const inputClass = "setem-form-input"

    // Inputs
    const [profile, setProfile] = useState(profiles.PROFESSIONNEL)
    const [societe, setSociete] = useState()
    const [adresse, setAdresse] = useState()
    const [adComplement, setAdComplement] = useState()
    const [codePostalObject, setCodePostalObject] = useState({
        first: "",
        second: "",
        third: "",
        fourth: "",
        fifth: "",
    })
    const [codePostal, setCodePostal] = useState()
    const [ville, setVille] = useState()
    const [pays, setPays] = useState()
    const [siret, setSiret] = useState()
    const [codeNAF, setCodeNAF] = useState()
    const [tva, setTva] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [website, setWebsite] = useState()
    const [nom, setNom] = useState()
    const [prenom, setPrenom] = useState()
    const [comment, setComment] = useState()
    const [selectedFile, setSelectedFile] = useState()
    const [base64, setBase64] = useState("")
    const [spinner, setSpinner] = useState(true)
    const [load, setLoad] = useState(true)
    const toast = useToast()
    const [reValue, setReValue] = useState(null)
    const reRef = useRef();


    useEffect(() => {
        setCodePostal(
            codePostalObject.first +
            codePostalObject.second +
            codePostalObject.third +
            codePostalObject.fourth +
            codePostalObject.fifth
        )
    })

    const isOutOfFrance = (translate) =>
        profile === profiles.PRO_ETRANGER ? " " + translate : ""

    const handleCodePostalChange = (key, e) => {
        setCodePostalObject({...codePostalObject, [key]: e.target.value})
        let data_id = e.target.dataset.id
        let next_target_id = parseInt(data_id) + 1
        if (next_target_id <= 5 && e.target.value !== "") {
            const next_target = document.querySelector(
                "[data-id='" + next_target_id + "']"
            )
            next_target.addEventListener("focus", () => {
                next_target.select()
            })
            next_target.focus()
        }
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        //console.log(base64)

        const token = await reRef.current.executeAsync();
        reRef.current.reset();
        if (!token)
            return

        setSpinner(false)

        const form = e.target

        const headers = {"Content-Type": "multipart/form-data"}
        const formData = new FormData(form)
        const data = {}

        formData.forEach(function (value, key) {
            if (key === "file") {
                data[key] = value
            } else if (key === "email") {
                data[key] = value ? value.toLocaleLowerCase() : ""
            } else {
                data[key] = value ? value.toUpperCase() : ""
            }
        })
        console.log(data)

        console.log(endPoint)
        console.log(email_from)
        console.log(base64)

        const transformedData = [
            {
                label: "PROFILE*",
                value: data.profile ? data.profile : "",
            },
            {
                label: `SOCIETE*${" " + isOutOfFrance("(company)")}`,
                value: data.societe ? data.societe : "",
            },
            {
                label: `ADRESSE*${" " + isOutOfFrance("(address)")}`,
                value: data.adresse ? data.adresse : "",
            },
            {
                label: "COMPLEMENT ADRESSE",
                value: data.comp_adresse ? data.comp_adresse : "",
            },
            {
                label: "CODE POSTAL*",
                value: data.code_postal ? data.code_postal : "",
            },
            {
                label: `VILLE*${" " + isOutOfFrance("(city)")}`,
                value: data.ville ? data.ville : "",
            },
            {
                label: `PAYS*${" " + isOutOfFrance("(country)")}`,
                value: data.pays ? data.pays : "",
            },
            {
                label: "SIRET*",
                value: data.siret ? data.siret : "",
            },
            {
                label: "CODE NAF*",
                value: data.code_naf ? data.code_naf : "",
            },
            {
                label: "TVA Intracommunautaire*",
                value: data.tva ? data.tva : "",
            },
            {
                label: "TELEPHONE*",
                value: data.phone ? data.phone : "",
            },
            {
                label: "EMAIL*",
                value: data.email ? data.email : "",
            },
            {
                label: `SITE WEB:${" " + isOutOfFrance("(web site)")}`,
                value: data.website ? data.website : "",
            },
            {
                label: `NOM*${" " + isOutOfFrance("(name)")}`,
                value: data.nom ? data.nom : "",
            },
            {
                label: `PRENOM*${" " + isOutOfFrance("(first name)")}`,
                value: data.prenom ? data.prenom : "",
            },
            {
                label: `DEMANDE DE DEVIS*${" " + isOutOfFrance("(comment)")}`,
                value: data.comment ? data.comment : "",
            },

            {
                label: `CODE SETEM`,
                value: codeSetem ? codeSetem : "",
            },
            {
                label: `DESIGNATION USINE`,
                value: desUsine ? desUsine : "",
            },
            {
                label: `CODE USINE`,
                value: codeUsine ? codeUsine : "",
            },
        ]

        const htmlData = buildHtmlList({data: transformedData})

        console.log(htmlData)

        fetch(endPoint, {
            mode: "no-cors",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: data,
                senderName: email_from,
                senderEmail: email_to,
                message: "",
                base64Data: base64,
                fileName: "Attachement",
                object: "Demande Devis",
                html: htmlData,
                token: token,
            }),
        })
            .then((r) => {
                console.log("SENDED")
                //Desactivation du spinner
                setSpinner(true)
                //Affichage du popup
                toast({
                    title: "Envoyée",
                    description: "Votre demande a bien été envoyée",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "bottom-right",
                })
                //reset les valeur du formulaire
                setSociete("")
                setAdresse("")
                setAdComplement("")
                setCodePostal("")
                setVille("")
                setPays("")
                setSiret("")
                setCodeNAF("")
                setTva("")
                setPhone("")
                setEmail("")
                setWebsite("")
                setNom("")
                setPrenom("")
                setComment("")
                setCodePostalObject({
                    first: "",
                    second: "",
                    third: "",
                    fourth: "",
                    fifth: "",
                })
                setProfile(profiles.PROFESSIONNEL)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleFileInput = (e) => {
        const file = e.target.files[0]
        //setSelectedFile(file);
        getBase64(file)
    }

    const getBase64 = (file) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            onLoad(reader.result)
        }
    }

    const onLoad = (filestring) => {
        setBase64(filestring)
    }

    const actualBtn =
        typeof document !== `undefined`
            ? document.getElementById("actual-btn")
            : null

    const fileChosen =
        typeof document !== `undefined`
            ? document.getElementById("file-chosen")
            : null

    function changeTextFileChosen(e) {
        fileChosen.textContent = e.target.files[0].name
    }

    const {emailQuoteRequestStatus} = useEmailQuoteRequestStatusContext()
    return (
        <Box p={{base: 2, md: 6}}>

            {!emailQuoteRequestStatus
                &&
                <QuoteHeader
                    code_setem = {codeSetem}
                    des_usine = {desUsine}
                    code_usine = {codeUsine}
                />}

            <Box px={{base: 2, md: 5, lg: 10}} py={5}>
                <Box display="flex" alignItems="center" justifyContent="start">
                    <Box className="coche" w="30px" h="25px">
                        <img
                            src={`https://${process.env.GATSBY_CURRENT_URL}/wp-content/uploads/2021/12/picto-marque.png`}
                            alt="coche logo"
                            height="100%"
                        />
                    </Box>
                    <h2>Nouveau Client</h2>
                </Box>
            </Box>
            <form
                onSubmit={(e) => handleSubmitForm(e)}
                method="post"
                encType="multipart/form-data"
            >
                <fieldset className="checkbox-wrapper">
                    <div className="radio-input">
                        <label className={labelClass}>
                            <input
                                type="radio"
                                value="professionnel"
                                name="profile"
                                checked={profile === profiles.PROFESSIONNEL}
                                onChange={(e) => setProfile(e.target.value)}
                            />
                            Nouveau client professionnel (français)
                        </label>
                    </div>
                    <div className="radio-input">
                        <label className={labelClass}>
                            <input
                                type="radio"
                                value="particulier"
                                name="profile"
                                checked={profile === profiles.PARTICULIER}
                                onChange={(e) => setProfile(e.target.value)}
                            />
                            Particulier (français)
                        </label>
                    </div>
                    <div className="radio-input">
                        <label className={labelClass}>
                            <input
                                type="radio"
                                value="pro-etranger"
                                name="profile"
                                checked={profile === profiles.PRO_ETRANGER}
                                onChange={(e) => setProfile(e.target.value)}
                            />
                            Nouveau client (hors France) + business out of France
                        </label>
                    </div>
                </fieldset>

                {profile !== profiles.PARTICULIER ? (
                    <label className={labelClass}>
                        {`SOCIETE:*${isOutOfFrance("(company)")}`}

                        <input
                            className={inputClass}
                            type="text"
                            name="societe"
                            value={societe}
                            onChange={(e) => setSociete(e.target.value)}
                            required="true"
                        />
                    </label>
                ) : null}
                {/* If profile is "particulier", put those two fields at the top of the form */}
                {profile === profiles.PARTICULIER ? (
                    <>
                        <label className={labelClass}>
                            {`NOM:*${isOutOfFrance("(name)")}`}
                            <input
                                className={inputClass}
                                type="text"
                                name="nom"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                                required="true"
                            />
                        </label>

                        <label className={labelClass}>
                            {`PRENOM:*${isOutOfFrance("(first name)")}`}
                            <input
                                className={inputClass}
                                type="text"
                                name="prenom"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                required="true"
                            />
                        </label>
                    </>
                ) : null}

                <label className={labelClass}>
                    {`ADRESSE:*${isOutOfFrance("(address)")}`}
                    <input
                        className={inputClass}
                        type="text"
                        name="adresse"
                        value={adresse}
                        onChange={(e) => setAdresse(e.target.value)}
                        required="true"
                    />
                </label>

                <label className={labelClass}>
                    COMPLEMENT ADRESSE:
                    <input
                        className={inputClass}
                        type="text"
                        name="comp_adresse"
                        value={adComplement}
                        onChange={(e) => setAdComplement(e.target.value)}
                    />
                </label>

                <label className={labelClass}>
                    CODE POSTAL:*
                    <fieldset>
                        <input type="hidden" name="code_postal" value={codePostal}/>
                        <input
                            className={inputClass + " code-postal"}
                            type="num"
                            value={codePostalObject.first}
                            maxLength={1}
                            data-id="1"
                            onChange={(e) => handleCodePostalChange("first", e)}
                            required="true"
                        />
                        <input
                            className={inputClass + " code-postal"}
                            type="num"
                            value={codePostalObject.second}
                            maxLength={1}
                            data-id="2"
                            //onChange={(e) => setCodePostalObject({...codePostalObject, second: e.target.value})}
                            onChange={(e) => handleCodePostalChange("second", e)}
                            required="true"
                        />
                        <input
                            className={inputClass + " code-postal"}
                            type="num"
                            value={codePostalObject.third}
                            maxLength={1}
                            data-id="3"
                            //onChange={(e) => setCodePostalObject({...codePostalObject, third: e.target.value})}
                            onChange={(e) => handleCodePostalChange("third", e)}
                            required="true"
                        />
                        <input
                            className={inputClass + " code-postal"}
                            type="num"
                            value={codePostalObject.fourth}
                            maxLength={1}
                            data-id="4"
                            //onChange={(e) => setCodePostalObject({...codePostalObject, fourth: e.target.value})}
                            onChange={(e) => handleCodePostalChange("fourth", e)}
                            required="true"
                        />
                        <input
                            className={inputClass + " code-postal"}
                            type="num"
                            value={codePostalObject.fifth}
                            maxLength={1}
                            data-id="5"
                            //onChange={(e) => setCodePostalObject({...codePostalObject, fifth: e.target.value})}
                            onChange={(e) => handleCodePostalChange("fifth", e)}
                            required="true"
                        />
                    </fieldset>
                </label>

                <label className={labelClass}>
                    {`VILLE:*${isOutOfFrance("(city)")}`}
                    <input
                        className={inputClass}
                        type="text"
                        name="ville"
                        value={ville}
                        onChange={(e) => setVille(e.target.value)}
                        required="true"
                    />
                </label>

                {profile === profiles.PRO_ETRANGER ? (
                    <label className={labelClass}>
                        {`PAYS:*${isOutOfFrance("(country)")}`}
                        <input
                            className={inputClass}
                            type="text"
                            name="pays"
                            value={pays}
                            onChange={(e) => setPays(e.target.value)}
                            required="true"
                        />
                    </label>
                ) : null}

                {profile === profiles.PROFESSIONNEL ? (
                    <>
                        <label className={labelClass}>
                            SIRET:*
                            <input
                                className={inputClass}
                                type="text"
                                name="siret"
                                value={siret}
                                onChange={(e) => setSiret(e.target.value)}
                                required="true"
                            />
                        </label>
                        <label className={labelClass}>
                            CODE NAF:*
                            <input
                                className={inputClass}
                                type="text"
                                name="code_naf"
                                value={codeNAF}
                                onChange={(e) => setCodeNAF(e.target.value)}
                                required="true"
                            />
                        </label>
                        <label className={labelClass}>
                            TVA Intracommunautaire:*
                            <input
                                className={inputClass}
                                type="text"
                                name="tva"
                                value={tva}
                                onChange={(e) => setTva(e.target.value)}
                                required="true"
                            />
                        </label>
                    </>
                ) : null}

                <label className={labelClass}>
                    TELEPHONE:*
                    <input
                        className={inputClass}
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required="true"
                    />
                </label>

                <label className={labelClass}>
                    EMAIL:*
                    <input
                        className={`${inputClass} text-lowercase`}
                        type="text"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required="true"
                    />
                </label>

                {profile !== profiles.PARTICULIER ? (
                    <label className={labelClass}>
                        {`SITE WEB:*${isOutOfFrance("(web site)")}`}
                        <input
                            className={inputClass}
                            type="text"
                            name="website"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </label>
                ) : null}
                {profile !== profiles.PARTICULIER ? (
                    <>
                        <label className={labelClass}>
                            {`NOM:*${isOutOfFrance("(name)")}`}
                            <input
                                className={inputClass}
                                type="text"
                                name="nom"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                                required="true"
                            />
                        </label>

                        <label className={labelClass}>
                            {`PRENOM:*${isOutOfFrance("(first name)")}`}
                            <input
                                className={inputClass}
                                type="text"
                                name="prenom"
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                                required="true"
                            />
                        </label>
                    </>
                ) : null}

                <label className={labelClass}>
                    {`DEMANDE DE DEVIS:*${isOutOfFrance("(comment)")}`}
                    <textarea
                        className={inputClass}
                        type="text"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required="true"
                        rows={10}
                    />
                </label>

                <fieldset className="file-input-fieldset">
                    <label className={labelClass} id="label-download">
                        Téléchargement fichier:
                    </label>
                    {/*
                    <AttachmentIcon style={{
                        position: "absolute",
                        right: "550px",
                        bottom: "29px"
                    }} w={6} h={6}
                    />
                    <input
                        className={inputClass}
                        type="file"
                        name="file"
                        onChange={(e) => handleFileInput(e)}
                        accept={"image/jpeg,image/png"}
                    />
                    */}

                    <div style={{marginLeft: "20px"}}>
                        <input
                            type="file"
                            name="file"
                            id="actual-btn"
                            accept={"image/jpeg,image/png"}
                            onChange={(e) => {
                                changeTextFileChosen(e)
                                handleFileInput(e)
                            }}
                            hidden
                        />

                        <label id="label-for-file" for="actual-btn">
                            Ajouter un fichier{" "}
                            <img
                                style={{
                                    width: "55px",
                                    marginLeft: "0px",
                                    paddingLeft: "30px",
                                }}
                                src={"/file-icon.svg"}
                            />
                        </label>
                        <br/>
                        <span id="file-chosen">Aucun fichier ...</span>
                    </div>
                    <div>
                        <button className="form-submit" type="submit">
                            Envoyer la demande&emsp;
                            <Spinner
                                color="white"
                                style={{position: "absolute"}}
                                hidden={spinner}
                            />
                        </button>
                    </div>
                </fieldset>
                <ReCAPTCHA
                    sitekey={site_key}
                    ref={reRef}
                    size="invisible"
                />

            </form>

            {/* {ReactHtmlParser(contactFormData.allWpPage.edges[0].node.content)} */}
        </Box>
    )
}

export default ContactForm
