import React from "react"
import { Box } from "@chakra-ui/react"
import ContactImage from "../../../assets/img/image_contact.jpeg"
const QuoteBody = () => {
  // const contactQuoteBody = useStaticQuery(graphql`
  //   query {
  //     allWpPage(filter: { title: { eq: "Contact_Deja-client_Body" } }) {
  //       edges {
  //         node {
  //           title
  //           content
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Box bg="#F5F5F5" px={{ base: 2, md: 5, lg: 10 }} py={30}>
      {/* {ReactHtmlParser(contactQuoteBody.allWpPage.edges[0].node.content)} */}

      <div>
        <h3>SIEGE SOCIAL ET ADMINISTRATIF</h3>
        <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-grid">
          <div>
            <div className="el-content uk-panel">
              <p>S.E.T.E.M</p>
              <p>885 chemin St-Bernard</p>
              <p>06220 Vallauris</p>
              <p>France</p>
            </div>
          </div>
        </div>
        <p>T. 0890 71 00 39 - T. 04 93 64 35 19 - F. 04 83 33 19 90</p>
        <p>
          {" "}
          <span>
            <a
              className="subhead-p dark-blue"
              href="mailTo:commercial@setem.fr"
            >
              commercial@setem.fr
            </a>
          </span>
        </p>
        <hr className="wp-block-separator" />

        <figure className="wp-block-image size-full">
          <img
            loading="lazy"
            width={279}
            height={65}
            className="wp-image-1061"
            src={ContactImage}
            alt="image contact"
          />
        </figure>
        <hr className="wp-block-separator" />
        <p className="cg-devis" />
        <h3>MAGASIN ET LOGISTIQUE</h3>
        <p>S.E.T.E.M</p>
        <p>ZAC de la Malnoue</p>
        <p>85 Avenue de l'Europe</p>
        <p>77184 Emerainville</p>
        <p>France</p>
        <p>
          {" "}
          <span>
            <a
              className="subhead-p dark-blue"
              href="mailTo:commercial@setem.fr"
            >
              commercial@setem.fr
            </a>
          </span>
        </p>
        <hr className="wp-block-separator" />
        <p className="cg-devis">
          Les informations figurant sur le formulaire de contact sont
          indispensables pour vous transmettre un devis. Si vous n'utilisez pas
          le formulaire ci-joint, les renseignements juridiques ainsi que
          l'adresse de votre société doivent apparaitre dans votre courriel.
          Suivant ces conditions, vous pouvez utiliser le bouton ci-dessous pour
          votre demande de devis, l'envoi de vos photos et documentations qui
          permettront de mieux définir votre produit:
        </p>
        <div className="wp-container-6246a7784778d wp-block-buttons">
          <div className="wp-block-button is-style-outline quote-button-second">
            <a
              className="wp-block-button__link"
              href="mailto:commercial@setem.fr"
            >
              Demande de devis par messagerie
            </a>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default QuoteBody
