import React, {useState, useEffect} from "react"
import {Flex, Box} from "@chakra-ui/react"
import {Footer} from "../components/footer/Footer.js"
import {Header} from "../components/header/Header.js"
import ContactForm from "../components/home/contact/ContactForm2.js"
import QuoteInfos from "../components/home/contact/QuoteInfos.js"
import {useEmailQuoteRequestStatusContext} from "../components/context/EmailQuoteRequestStatusProvider.js"

const isBrowser = typeof window !== "undefined";

const ContactPage = () => {
    const {emailQuoteRequestStatus} = useEmailQuoteRequestStatusContext()
    let urlParams;
    if (isBrowser) {
        urlParams = new URLSearchParams(window.location.search);
    }

    return (
        <div>
            <Header/>
            <Box
                display={{md: "flex"}}
                p={{base: 3, md: 5, lg: 10}}
                justifyContent={emailQuoteRequestStatus ? "center" : "unset"}
                color="#2c68a5"
            >
                <Box
                    w={{
                        lg: emailQuoteRequestStatus ? "70%" : "50%",
                        md: emailQuoteRequestStatus ? "80%" : "50%",
                    }}
                >
                    <QuoteInfos
                        code_setem={(isBrowser) ? urlParams.get('code_setem') : null}
                        des_usine={(isBrowser) ? urlParams.get('des_usine') : null}
                        code_usine={(isBrowser) ? urlParams.get('code_usine') : null}
                    />
                </Box>

                {!emailQuoteRequestStatus ? (
                    <Box w={{md: "50%"}}>
                        <ContactForm
                            code_setem={(isBrowser) ? urlParams.get('code_setem') : null}
                            des_usine={(isBrowser) ? urlParams.get('des_usine') : null}
                            code_usine={(isBrowser) ? urlParams.get('code_usine') : null}
                        />
                    </Box>
                ) : null}
            </Box>
            <Footer/>
        </div>
    )
}

export default ContactPage
