import React from "react"
import { Box } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"

const QuoteSubHeader = () => {
  // const contactQuoteSubHead = useStaticQuery(graphql`
  //   query {
  //     allWpPage(filter: { title: { eq: "Contact_Deja-client_SubHead" } }) {
  //       edges {
  //         node {
  //           title
  //           content
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Box px={{ base: 1, md: 5, lg: 10 }} py={5}>
      <p class="subhead-p">Demandes de devis exclusivement par courriel.</p>

      <p class="el-content uk-panel">
        Nos services sont à votre disposition pour répondre à vos appels
        téléphoniques toutefois toutes les demandes de devis doivent être
        envoyées par courriel, soit en remplissant les formulaires à votre
        disposition à droite sur cette page internet, soit en nous écrivant à{" "}
        <span>
          <a className="subhead-p dark-blue" href="mailTo:commercial@setem.fr">
            commercial@setem.fr
          </a>
        </span>
      </p>
      {/* {ReactHtmlParser(contactQuoteSubHead.allWpPage.edges[0].node.content)} */}
    </Box>
  )
}

export default QuoteSubHeader
