import React from "react"
import {Box} from "@chakra-ui/react"
import "./quoteHeader.css"
import PictoMarque from "../../../assets/img/picto-marque.png"
import {useEmailQuoteRequestStatusContext} from "../../context/EmailQuoteRequestStatusProvider"
import QuoteHeaderSubform from "./QuoteHeaderSubform/QuoteHeaderSubform"

const QuoteHeader = (props) => {
    const {setEmailQuoteRequestStatus, emailQuoteRequestStatus} =
        useEmailQuoteRequestStatusContext()

    const buttonText = emailQuoteRequestStatus
        ? "Retour. Je ne dispose pas de mon code client."
        : "Demande de devis par courriel"

    return (
        <Box bg="#F5F5F5" px={{base: 2, md: 5, lg: 10}} py={5}>
            <Box display="flex" alignItems="center" justifyContent="start">
                <Box className="coche" w="30px" h="25px">
                    <img src={PictoMarque} alt="coche logo" height="100%"/>
                </Box>
                <h2>Déjà Client</h2>
            </Box>

            <div className="wp-container-6246a778611fc wp-block-buttons">
                <div
                    className="wp-block-button is-style-outline quote-head-button"
                    onClick={() => {
                        console.log("click")
                        setEmailQuoteRequestStatus((status) => !status)
                    }}
                >
                    <a className="wp-block-button__link">{buttonText}</a>
                </div>

                {emailQuoteRequestStatus
                    ?
                    <QuoteHeaderSubform
                        code_setem = {props.code_setem ? props.code_setem : ""}
                        des_usine = {props.des_usine ? props.des_usine : ""}
                        code_usine = {props.code_usine ? props.code_usine : ""}
                    />
                    : null}
            </div>
        </Box>
    )
}

export default QuoteHeader
